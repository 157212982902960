import React, { useEffect, useReducer, useRef } from 'react';
import _ from 'lodash';
import { Button, ButtonToolbar, Form } from 'rsuite';
import { Address, AddressType } from '../../api/address/addressApiTypes';
import { getAddress, updateAddress } from '../../api/address/address';
import showSuccessMessage from '../../utils/message';

interface Props {
  orderId: number;
  addressType: AddressType;
  onCancel?: () => void;
}

type Status = 'ready' | 'loading' | 'saving';

type Edit = {
  [key in keyof Omit<Required<Address>, 'originalId'>]: string;
};

const initialEdit: Edit = {
  name: '',
  company: '',
  phone: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  note: '',
  registrationNumber: '',
  vatNumber: '',
};

interface State {
  status: Status,
  address?: Address
  edit: Edit;
}

const initialState: State = {
  status: 'ready',
  edit: initialEdit,
};

type StatusSetAction = { type: 'status/set', payload: Status };
type AddressSetAction = { type: 'address/set', payload: Address };
type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = StatusSetAction | AddressSetAction | EditSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'address/set': {
      return {
        ...state,
        address: action.payload,
        edit: _.defaults(action.payload, initialEdit),
      };
    }
    case 'edit/set': {
      return { ...state, edit: action.payload };
    }
    default:
      return state;
  }
}

export default function AddressEdit({
  orderId,
  addressType,
  onCancel,
}: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortControllerRef = useRef<AbortController>(new AbortController());

  async function fetchAddress() {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const address = await getAddress(orderId, addressType, abortControllerRef.current.signal);
      dispatch({ type: 'address/set', payload: address });
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveAddress() {
    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      const address = _.omitBy(state.edit, _.isEmpty);
      await updateAddress(orderId, addressType, address, abortControllerRef.current.signal);
      showSuccessMessage('Address successfully updated.');
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  useEffect(() => {
    fetchAddress().then();

    return () => {
      abortControllerRef.current?.abort();
    };
  }, [orderId, addressType]);

  function handleCancel() {
    onCancel?.();
  }

  function handleSave() {
    saveAddress().then();
  }

  return (
    <Form
      layout="vertical"
      formValue={state.edit}
      onChange={(edit) => dispatch({ type: 'edit/set', payload: edit as Edit })}
    >
      <Form.Group>
        <Form.ControlLabel>
          Street 1
        </Form.ControlLabel>
        <Form.Control
          name="addressLine1"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Street 2
        </Form.ControlLabel>
        <Form.Control
          name="addressLine2"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          City
        </Form.ControlLabel>
        <Form.Control
          name="city"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Zip code
        </Form.ControlLabel>
        <Form.Control
          name="zip"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Company
        </Form.ControlLabel>
        <Form.Control
          name="company"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          Phone
        </Form.ControlLabel>
        <Form.Control
          name="phone"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel>
          E-mail
        </Form.ControlLabel>
        <Form.Control
          name="email"
          type="text"
        />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="ghost"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          <Button
            appearance="primary"
            onClick={() => handleSave()}
            type="submit"
            loading={state.status !== 'ready'}
          >
            Save
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}

AddressEdit.defaultProps = {
  onCancel: undefined,
};
