import React, { useEffect, useState } from 'react';
import { Loader, Stack } from 'rsuite';
import Enumerable from 'linq';
import { FaBuilding, FaEnvelope, FaPhone } from 'react-icons/fa';
import { useStoreActions } from '../../store/hooks';
import { OrderListItem, OrderPreview } from '../../api/apiTypes';
import CopyToClipboardIcon from '../CopyToClipboardIcon';
import formatMoneyValue from '../../utils/localization';
import AddressEditModal from '../Address/AddressEditModal';

interface Props {
  order: OrderListItem
}

export default function OrderPreviewRow({ order }: Props) {
  const { getOrderPreview } = useStoreActions(actions => actions.orders);
  const [preview, setPreview] = useState<OrderPreview | null>();
  const { id } = order;

  useEffect(() => {
    getOrderPreview(id).then((p: OrderPreview) => {
      setPreview(p);
    });
  }, [id]);

  const getTrackingRows = () => {
    if (!preview) {
      return null;
    }
    const tracking = preview.parcelTracking;
    const count = Math.max(
      tracking.customer.length,
      tracking.crossDock.length,
    );

    return Enumerable.range(0, count).select(i => (
      <tr key={i}>
        <td>
          {i < tracking.customer.length && (
          <span>
            {tracking.customer[i]}
            {' '}
            <CopyToClipboardIcon text={tracking.customer[i]} />
          </span>
          )}
        </td>
        <td>
          {i < tracking.crossDock.length && (
          <span>
            {tracking.crossDock[i]}
            {' '}
            <CopyToClipboardIcon text={tracking.crossDock[i]} />
          </span>
          )}
        </td>
      </tr>
    )).toArray();
  };

  if (!preview) {
    return <Loader center size="md" />;
  }

  return (
    <Stack spacing={15} alignItems="flex-start" justifyContent="flex-start">
      <div className="order-preview-container">
        <table className="order-preview-table sticky-header">
          <colgroup>
            <col width={120} />
            <col width={400} />
            <col width={50} />
            <col width={120} />
            <col width={30} />
          </colgroup>
          <thead>
            <tr>
              <td colSpan={5}>Products</td>
            </tr>
          </thead>
          <tbody>
            {preview.products.map(product => (
              <tr key={`product.${product.orderProductCode}`}>
                <td>
                  {product.orderProductCode}
                </td>
                <td>
                  {product.title}
                </td>
                <td>
                  {product.quantity}
                  x
                </td>
                <td>
                  {formatMoneyValue(product.price)}
                  {' '}
                  {product.currency}
                </td>
                <td>
                  {product.vat}
                  %
                </td>
              </tr>

            ))}
          </tbody>
        </table>
      </div>
      <div className="order-preview-container">
        <table className="order-preview-table">
          <colgroup>
            <col width={20} />
            <col width={350} />
          </colgroup>
          <thead>
            <tr>
              <td colSpan={2}>Customer</td>
              <td colSpan={2}><AddressEditModal order={order} /></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><FaPhone /></td>
              <td>
                {preview.customer.phone}
                {' '}
                <CopyToClipboardIcon text={preview.customer.phone} />
              </td>
            </tr>
            <tr>
              <td><FaEnvelope /></td>
              <td>
                {preview.customer.email}
                {' '}
                <CopyToClipboardIcon text={preview.customer.email} />
              </td>
            </tr>
            <tr>
              <td />
              <td>
                {preview.customer.name}
                {' '}
                {preview.customer.company}
              </td>
            </tr>
            <tr>
              <td><FaBuilding /></td>
              <td>{preview.customer.address1}</td>
            </tr>
            <tr>
              <td />
              <td>
                {preview.customer.zip}
                {' '}
                {preview.customer.city}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="order-preview-container">
        <table className="order-preview-table sticky-header">
          <colgroup>
            <col width={200} />
            <col width={200} />
          </colgroup>
          <thead>
            <tr><td colSpan={2}>Parcel tracking</td></tr>
          </thead>
          <tbody>
            <tr>
              <td><b>Customer tracking</b></td>
              <td><b>Crossdock tracking</b></td>
            </tr>
            {getTrackingRows()}
          </tbody>
        </table>
      </div>
    </Stack>
  );
}
